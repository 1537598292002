import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Countrycode from "../Countrycode.json";
import * as yup from "yup";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { getAdminprofile, getuserprofile } from "../Redux/Actions/AdminActions";




export default function MyProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  
  const [admindetails, setAdminDetails] = useState({});

  const dispatch = useDispatch();
  const {id} = useParams();


  useEffect(() => {
    dispatch(getuserprofile({id:id}))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.status === 200) {
          setAdminDetails(response?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);



 

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                User Profile{" "}
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="my-profile-page mt-4">
         
              <Form >
                
                <div className="profile-top-box">
                  <div className="photo-upload">
                    <div className="profile-picture">
                    <img
                      
                         src={`${url}public/${admindetails?.image}`}
                         alt="Profilesss"
                       
                    />
                    </div>
                
                  </div>

                  <div className="profile-disp mt-5">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="type"
                          placeholder="First Name"
                           value={admindetails?.first_name || "N/A"}
                        />
                        
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="type"
                          placeholder="Last Name"
                         
                        />
                        
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                        
                        />
                      </Form.Group>

                      {/* <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          name="phone_number"
                          value={values?.phone_number}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="phone_number"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group> */}
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPhone"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <div className="d-flex">
                          {/* <Form.Select
                            className="me-2"
                            style={{ maxWidth: "120px" }}
                            name="country_code"
                            value={values?.country_code}
                            onChange={(e) =>
                              setFieldValue("country_code", e.target.value)
                            }
                          >
                            {Countrycode?.map(({ dial_code, name, flag }) => (
                              <option key={dial_code} value={dial_code}>
                                {flag} {dial_code}
                              </option>
                            ))}
                          </Form.Select> */}
                          <Form.Control
                            type="tel"
                            placeholder="Phone Number"
                            
                          />
                        </div>
                        
                      </Form.Group>
                    </div>
                  </div>

                  
                </div>
              </Form>
            
          <Row>
            <Col lg={12}>
              <div className="profile-top-box mt-4">
                <div className="heading-profile">
                  <h2>Document</h2>
                  <div className="upld-photo-lable ">
                    <div className="iupld-photo-item">
                      <p>Identity proof</p>
                      <img
                        // src={`${url}public/${admindetails?.image}`}
                        alt="Profile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
             
                  <Form >
                    <div className="profile-top-box mt-4">
                      <div className="heading-profile">
                        <h2>Activity</h2>
                      </div>
                      <div className="activity-list-user">
                        <ul>
                          <li>
                            <p>[Admin Name] made updates to profile.</p>
                            <div>01/01/2023 13:32</div>
                          </li>
                          <li>
                            <p>[Admin Name] made updates to profile.</p>
                            <div>01/01/2023 13:32</div>
                          </li>{" "}
                          <li>
                            <p>[Admin Name] made updates to profile.</p>
                            <div>01/01/2023 13:32</div>
                          </li>{" "}
                          <li>
                            <p>[Admin Name] made updates to profile.</p>
                            <div>01/01/2023 13:32</div>
                          </li>{" "}
                          <li>
                            <p>[Admin Name] made updates to profile.</p>
                            <div>01/01/2023 13:32</div>
                          </li>{" "}
                        </ul>
                      </div>
                    </div>
                  </Form>
              
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Delete Account</h2>
                      <p>
                        Once you delete your account, you can not retrieve the
                        account. Please be certain.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="submit">Delete Account</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Suspend Account</h2>
                      <p>
                        You will not be able to receive messages, notifications
                        for up to 24hours.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="submit">Suspend Account</button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
