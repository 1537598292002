import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";


// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
    "chnagePassword",
    async (details) => {
      const data = await AdminAPI.patch(`/change-password`, details);
      console.log(data);
      return data;
    }
  );


  // ===== Get Admin Profile ==========


export const  getAdminprofile = createAsyncThunk(
  "getAdminprofile",
  async()=> {
     const {data} = await AdminAPI.get(`/profile`)
     return data;
  }
);


// ====== Edit admin profile ==========

export const Editadminprofile = createAsyncThunk(
  "Editadminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/profile`, details);
    console.log(data);
    return data;
  }
);

// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/users?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }


    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


  // ======== Delete User account api =============

  export const DeleteUser = createAsyncThunk(
    "DeleteUser",
    async(details) => {
        const data = await AdminAPI.delete(`/user/${details?.id}`);
       return data;
    } 
  );

  // =======  Dowanload Users List ============

  export const DowanloadUserxlsv = createAsyncThunk(
    "DowanloadUserxlsv",
    async () => {
      const data = await AdminAPI.get(`/users`);
      console.log(data);
      return data;
    }
  );


  // ===== Forget Password =======

export const forgetPass = createAsyncThunk(
  "forgetPass",
  async(details) => {
      const data = await AdminAPI.post(`/forgot-password`,details);
     return data;
  } 
);


// ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
  "ResetPass",
  async(details) => {
      const data = await AdminAPI.post(`/set-password`,details);
     return data;
  } 
);



// ===== Get user Profile =====

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/user/${details?.id}`);
    console.log(data);
    return data;
  }
);