import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Countrycode from "../Countrycode.json";
import * as yup from "yup";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Editadminprofile,
  chnagePassword,
  getAdminprofile,
} from "../Redux/Actions/AdminActions";
import { Button } from "react-bootstrap";
const validationSchema = yup.object().shape({
  old_password: yup.string().required("Required"),
  new_password: yup
    .string()
    .min(8, "New password must be at least 8 characters long")
    .matches(
      /[a-z]/,

      "Must contain at least one lowercase letter"
    )
    .matches(
      /[A-Z]/,

      "  Must contain at least one uppercase letter"
    )
    .matches(/[0-9]/, " Must contain at least one number")
    .matches(
      /[\W_]/,

      "Must contain at least one special character"
    )
    .required("Required"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Required"),
});

// validation schema for admin edit details

const validationschemaforEditdetails = yup.object({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),

  phone_number: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
});

export default function SupportChat() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [admindetails, setAdmindetails] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialvalues = {
    old_password: "",
    new_password: "",
    confirmpassword: "",
  };

  const handleChangepassword = (values, resetForm) => {
    dispatch(
      chnagePassword({
        old_password: values?.old_password,
        new_password: values?.new_password,
      })
    )
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);

          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/";
          }, 1500);

          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  useEffect(() => {
    dispatch(getAdminprofile())
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.status === 200) {
          setAdmindetails(response?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  // initailValues for edit profile

  const initialvaluesEditdetails = {
    first_name: admindetails?.first_name || "",
    last_name: "",
    email: "",
    country_code: "",
    phone_number: "",
    image: null,
  };

  const [formInitialValues, setFormInitialValues] = useState(
    initialvaluesEditdetails
  );
  console.log(formInitialValues);

  useEffect(() => {
    if (admindetails) {
      setFormInitialValues({
        first_name: admindetails.first_name || "",
        last_name: admindetails.last_name || "",
        email: admindetails.email || "",
        phone_number: admindetails.phone_number || "",
        country_code: admindetails.country_code || "",
        image: admindetails.image || "",
      });
    }
  }, [admindetails]);

  const handleProfileedit = (values, resetForm) => {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("country_code", values.country_code);
    formData.append("phone_number", values.phone_number);
    console.log(values.image, "sdfsdfsdfsdf");
    if (values.image) {
      formData.append("image", values.image);
    }

    dispatch(Editadminprofile(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setAdmindetails((prevState) => ({
            ...prevState,
            ...res.payload.data.data,
          }));
          toast.success(res?.payload?.data?.message);
          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // setCurruntImage("");
      setFieldValue("image", file);
      const url = URL.createObjectURL(file);
      setImagePreview(url);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/support">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Support Chat
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <Row className="justify-content-center mt-5">
          <Col lg={12}>
            <div className="support-chat-box-main">
              <div className="support-inner">
                <div className="support-msg-box">
                  <div className="user-box">
                    <div className="user-top-hdng">
                      <img src={require("../Assets/Images/user.png")} />
                      <h3>name</h3>
                      <h4>08:13 pm</h4>
                    </div>
                    <div className="user-msg-box">
                      <p>Hey I'm John Smith</p>
                    </div>
                  </div>
                </div>
              </div>
              <form>
                <div class="send-box">
                  <div class="mb-3 input-group">
                    <input
                      placeholder="Type your message here..."
                      aria-label="send"
                      aria-describedby="basic-addon2"
                      name="message"
                      type="text"
                      class="send-feild form-control"
                      value=""
                    />
                    <button
                      class="input-group-text"
                      id="basic-addon2"
                      type="submit"
                    >
                      Send
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M6.66406 9.33333L13.9974 2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.003 2L9.66964 14C9.60991 14.1303 9.47969 14.2139 9.33631 14.2139C9.19293 14.2139 9.06271 14.1303 9.00298 14L6.66964 9.33333L2.00298 7C1.87263 6.94027 1.78906 6.81004 1.78906 6.66667C1.78906 6.52329 1.87263 6.39306 2.00298 6.33333L14.003 2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
