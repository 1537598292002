import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponet = ({ currentPage, totalCount, limit, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / limit);
  const pageItems = [];
  for (let number = 0; number < totalPages; number++) {
    pageItems.push(
      <Pagination.Item
      key={number}
      active={number === currentPage}
      onClick={() => onPageChange(number)} // Pass zero-based page number
    >
      {number + 1} {/* Display 1-based page number */}
    </Pagination.Item>
    );
  }

  return (
    <Pagination>
    <Pagination.Prev
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 0} // Disable if on first page (0)
    />
    {pageItems}
    <Pagination.Next
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages - 1} // Disable if on last page
    />
  </Pagination>
  );
};

export default PaginationComponet;

